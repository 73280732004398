import React from "react";
import PortableText from "react-portable-text";

export default function Description({ data }) {
  return (
    <div className="mb-[25px]">
      <PortableText
        content={data}
        className="text-base leading-[26px] text-assent font-semibold block-content max-w-none"
      />
    </div>
  );
}
